// pages/patient.js
import React from "react"
import { graphql, Link　} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


const ArticlesPage = ({ data }) => (
 <Layout>
   <SEO title="SEO用のタイトル" />

   {data.allMicrocmsArticles.edges.map(edge => {
     const articles = edge.node
     const category = edge.node.category[0].name
     console.log('◆categoryは　' + category)

     return (
      <React.Fragment key={articles.id}>
        <div>
          <Link to={`/${category}/${articles.id}`}>
            <h2>{articles.title}</h2>
          </Link>
          <p
            dangerouslySetInnerHTML={{
              __html: `${articles.content}`,
            }}
          ></p>
          <img
            src={articles.thumbnail.url}
            width={300}
            //height={110}
            alt="pict画像"
          />
        </div>
        <div>
            {articles.category.map(category => (
              <React.Fragment key={category.id}>
                <span>カテゴリー：{category.name}</span>
              </React.Fragment>
            ))}
      </div>
      <hr />
      </React.Fragment>
      )
   })}
 </Layout>
)

export const query = graphql`
 {
    allMicrocmsArticles(
     sort: { fields: [createdAt], order: DESC }
   ) {
     edges {
       node {
         id
         title
         content
         category {
           id
           name
         }
         thumbnail {
           url
         }
       }
     }
   }
 }
`

export default ArticlesPage